import env from '@beam-australia/react-env';

const envConfig = {
    development: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/integration',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://int-subscriptions.ascendloans.com',
        },
        debitProviderKey: 'x895N42',
        arPortal: {
            baseURL: 'https://stg-resolve.ascendloans.com',
        },
        segment: {
            WRITE_KEY: 'S9kFRTq7jpC0puezwsLXaCbU87iDRjhB',
            DEBUG: true,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: '0c02c360-b55c-11ee-8fa8-91f0e571f91d',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://int-selfservice.ascendloans.com/clone',
        teamLeadGroups: ['Ascend Supervisor'],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
        },
        fees: {
            manualPaymentProcessing: 5,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: false,
            },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },

            timeZone: 'America/Los_Angeles',
        },
    },
    integration: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/integration',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://int-subscriptions.ascendloans.com',
        },
        debitProviderKey: 'x895N42',
        arPortal: {
            baseURL: 'https://stg-resolve.ascendloans.com',
        },
        segment: {
            WRITE_KEY: 'aIXmOQMMy220MQhwfDRzewC5ZDKfsAR1',
            DEBUG: true,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: '0c02c360-b55c-11ee-8fa8-91f0e571f91d',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://int-selfservice.ascendloans.com/clone',
        teamLeadGroups: ['Ascend Supervisor'],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
        },
        fees: {
            manualPaymentProcessing: 5,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: false,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },

            timeZone: 'America/Los_Angeles',
        },
    },
    staging: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/staging',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://stg-subscriptions.ascendloans.com',
        },
        debitProviderKey: 'x895N42',
        arPortal: {
            baseURL: 'https://stg-resolve.ascendloans.com',
        },
        segment: {
            WRITE_KEY: 'aIXmOQMMy220MQhwfDRzewC5ZDKfsAR1',
            DEBUG: true,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: '0c02c360-b55c-11ee-8fa8-91f0e571f91d',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://stg-selfservice.ascendloans.com/clone',
        teamLeadGroups: ['Ascend Supervisor'],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
        },
        fees: {
            manualPaymentProcessing: 5,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: false,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },
            timeZone: 'America/Los_Angeles',
        },
    },
    production: {
        cdn: {
            URL: 'https://bw-portal-cdn.s3.amazonaws.com/production',
        },
        sentry: {
            DSN: 'https://bd5a077acae4476abb946becd2a70303@o112787.ingest.sentry.io/6682910',
        },
        subscriptionCenter: {
            URL: 'https://subscriptions.ascendloans.com',
        },
        debitProviderKey: '73Xxu98',
        arPortal: {
            baseURL: 'https://resolve.ascendloans.com',
        },
        segment: {
            WRITE_KEY: '6f2QVQPooxrLrnIf3znG7ecXyENnikHP',
            DEBUG: false,
        },
        refiner: {
            projectId: 'd2a75d90-7f33-11ee-b474-21cca47f850d',
            loanCancellationSurveyId: '0c02c360-b55c-11ee-8fa8-91f0e571f91d',
            enableSurveys: true,
        },
        enableRefinance: true,
        enableCleanContact: true,
        enableAutoAchRevoke: true,
        enableCloneApp: true,
        sendReapplyLink: {
            enableSms: false,
            enableEmail: true,
        },
        cloneLink: 'https://selfservice.ascendloans.com/clone',
        teamLeadGroups: [],
        hardshipDeferment: {
            enabled: true,
            maxAllowedMonthly: 1,
            maxAllowedSemiMonthly: 2,
            maxAllowedBiWeekly: 2,
            maxAllowedWeekly: 4,
        },
        waiveBalanceReasons: {
            deceased: 'Deceased',
            identityTheft: 'Identity Theft',
            activeMilitary: 'Active Military',
        },
        fees: {
            manualPaymentProcessing: 5,
        },
        futurePaymentsDocument: {
            maxListedPayments: 5,
            hideForFatalReturnLoans: false,
        },
        cancelLoan: {
            deadline: {
                hour: 15,
                minute: 0,
            },
            timeZone: 'America/Los_Angeles',
        },
    },
};

export default envConfig[env('ENVIRONMENT')];
